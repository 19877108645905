import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer/Footer";
import { Link } from 'react-router-dom';


function NotFound() {
  return (
    <div>
      <Header activePage="Products"/>
        <div style={{height: "80vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>

        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <Link to="/">Go back to homepage</Link>
        
        </div>
      <Footer/>
    </div>
  );
}

export default NotFound;