import Header from "../components/Header/Header";
import React from "react";
import Footer from "../components/Footer/Footer";

function Insights() {
  return (
    <div>
         <Header activePage="Insights" />
         <div style={{height: "80vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>

         <h1>Coming Soon</h1>
         </div>

         <Footer/>
    </div>
  )
}

export default Insights